<template>
    <div class="container-fluid mt-3">
      <b-button variant="outline-primary" class="mb-3" @click="goBack"><i class="bi bi-arrow-left-circle-fill"></i> Kembali</b-button>
      <br>
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary"></h6>
          <button
      class="btn btn-primary mr-2 d-none d-lg-inline-block lg-show"
      type="button"
      @click="exportData"
    >
      Export <i class="bi-file-earmark mr-2"></i>
    </button>
        </div>
  
        <!-- Card Body -->
        <div class="card-body">

          <!-- Error Message -->
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <center>
           <img :src="require('../../assets/images/logoenjoy.png')"  alt="Logo" />
           <br><br>
           <h4 style="color: black;">Laporan Kegiatan Usaha {{ lkuData. unit_usaha.nama_unit_usaha }}</h4>
           <br>
         </center>
          <!-- LKU Data Display -->
            <div class="table-responsive">
                <table class="table table-bordered" width="100%" cellspacing="0">
                
                    <tbody><tr>
                        <th>Field</th>
                        <th>Data</th>
                    </tr>
                    <tr>
                        <td>Tahun Pelaporan</td>
                        <td>{{ lkuData.tahun_pelaporan }}</td>
                    </tr>
                    <tr>
                        <td>Periode</td>
                        <td>Semester {{ lkuData.semester }}</td>
                    </tr>
                    <tr>
                        <td>Tanggal Pelaporan</td>
                        <td>{{ lkuData.tanggal_pelaporan }}</td>
                    </tr>
                    
                    <tr>
                        <td>Tipe Usaha</td>
                        <td>{{ lkuData.unit_usaha.tipe_usaha }} </td>
                    </tr>
                                                            
                                        
                    <tr>
                        <td>Luas Tanah</td>
                        <td>{{ lkuData.luas_tanah }} m<sup>2</sup></td>
                    </tr>
                    <tr>
                        <td>Luas Bangunan</td>
                        <td>{{ lkuData.luas_bangunan }} m<sup>2</sup></td>
                    </tr>
                    <tr>
                        <td>Jumlah Lantai</td>
                        <td>{{ lkuData.jumlah_lantai }}</td>
                    </tr>
                 <!-- Dynamic from form -->
<tr v-for="(form, index) in lkuData.single_forms" :key="index">
  <!-- Display the label -->
  <td>{{ form.label }}</td>

  <!-- Display the value based on the input type -->
  <td>
    <!-- Check if the input type is 'number' -->
    <span v-if="form.tipe_inputan.tipe_inputan === 'number'">
      {{ form[form.slug] || 'N/A' }}
    </span>
     <!-- Check if the input type is 'number' -->
     <span v-if="form.tipe_inputan.tipe_inputan === 'uang ( Rp )'">
      Rp {{ formatRupiah(form[form.slug]) || 'N/A' }}
    </span>
      <!-- Check if the input type is 'number' -->
      <span v-if="form.tipe_inputan.tipe_inputan === 'text'">
            {{ form[form.slug] || 'N/A' }}
          </span>
  <!-- Check if the input type is 'select' and display the corresponding value -->
<span v-else-if="form.tipe_inputan.tipe_inputan === 'select'">
  <span v-if="form.options.length > 0 && form[form.slug] !== undefined">
    {{ 
      (form.options.find(option => option.option_id === form[form.slug]) != null) 
      ? form.options.find(option => option.option_id === form[form.slug]).value 
      : 'N/A' 
    }}
  </span>
  <span v-else>N/A</span>
</span>
<!-- Check if the input type is 'select' and display the corresponding value -->
<span v-else-if="form.tipe_inputan.tipe_inputan === 'radio'">
  <span v-if="form.options.length > 0 && form[form.slug] !== undefined">
    {{ 
      (form.options.find(option => option.option_id === form[form.slug]) != null) 
      ? form.options.find(option => option.option_id === form[form.slug]).value 
      : 'N/A' 
    }}
  </span>
  <span v-else>N/A</span>
</span>
<!-- Check if the input type is 'checkbox' -->
<span v-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
  <span v-for="(item, index) in form[form.slug]" :key="index">
    {{ item.value }}<span v-if="index < form[form.slug].length - 1">, </span>
  </span>
</span>

  


   
  </td>
</tr>


                    <tr>
                        <td>Fasilitas</td>
                        <td> <span v-for="(fasilitas, index) in lkuData.fasilitas" :key="index">
    {{ fasilitas.nama_fasilitas }}<span v-if="index < lkuData.fasilitas.length - 1">, </span>
  </span>
      
                                 </td>
                    </tr>             
                    <tr><td colspan="2" style="background-color: #dfd8d8; color:black;">
                        Jumlah Pengunjung 
                    </td>
                    </tr><tr>
                        <td>Bulan 1</td>
                        <td>{{ lkuData.pengunjung_bulan_1 }}</td>
                    </tr>
                    <tr>
                        <td>Bulan 2</td>
                        <td>{{ lkuData.pengunjung_bulan_2 }}</td>
                    </tr>
                    <tr>
                        <td>Bulan 3</td>
                        <td>{{ lkuData.pengunjung_bulan_3 }}</td>
                    </tr>
                    <tr>
                        <td>Bulan 4</td>
                        <td>{{ lkuData.pengunjung_bulan_4 }}</td>
                    </tr>
                    <tr>
                        <td>Bulan 5</td>
                        <td>{{ lkuData.pengunjung_bulan_5 }}</td>
                    </tr>
                    <tr>
                        <td>Bulan 6</td>
                        <td>{{ lkuData.pengunjung_bulan_6 }}</td>
                    </tr>

                </tbody>
                </table>
            </div>

            <div v-if="lkuData.punya_ruang_pertemuan === 1">
            <div class="table-responsive">
              <table class="table table-bordered" width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>Jenis Ruang Pertemuan</th>
                    <th>Nama</th>
                    <th>Kapasitas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ruang) in lkuData.ruang_pertemuans" :key="ruang.ruang_pertemuan_id">
                    <td>{{ ruang.jenis_ruang_pertemuan }}</td>
                    <td>{{ ruang.nama_ruang_pertemuan }}</td>
                    <td>{{ ruang.kapasitas_ruang_pertemuan }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-for="(grup) in lkuData.grup_forms" :key="grup.grup_id">
  <h3>{{ grup.nama_grup }}</h3>
  <div class="table-responsive">
    <table class="table table-bordered" width="100%" cellspacing="0">
      <thead>
        <tr>
          <th>Label</th>
          <th>Nilai</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(form) in grup.forms" :key="form.slug">
          <td>{{ form.label }}</td>
          <td>
            <!-- Check if the input type is 'select' -->
            <template v-if="form.tipe_inputan.tipe_inputan === 'select'">
              <!-- Find the selected option value by matching the option_id -->
              {{ form.options.find(option => option.option_id == form[form.slug]).value || 'N/A' }}
            </template>
            <!-- For other input types (e.g., text) -->
            <template v-else>
              {{ form[form.slug] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>



<div v-for="(subGrup) in lkuData.sub_grup_forms" :key="subGrup.grup_id">
  <h3>{{ subGrup.nama_grup }}</h3>
  <div v-for="(subGrupDetail) in subGrup.sub_grup" :key="subGrupDetail.sub_grup_id">
    <h4>{{ subGrupDetail.nama_sub_grup }}</h4>
    <div class="table-responsive">
      <table class="table table-bordered" width="100%" cellspacing="0">
        <thead>
          <tr>
            <th>Label</th>
            <th>Nilai</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(form) in subGrupDetail.forms" :key="form.slug">
            <td>{{ form.label }}</td>
            <td>
              <!-- Handle select inputs -->
              <template v-if="form.tipe_inputan.tipe_inputan === 'select'">
                {{ form.options.find(option => option.option_id == form[form.slug]).value || 'N/A' }}
              </template>
              
              <!-- Handle checkbox inputs -->
              <template v-else-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
                <!-- Assuming checkbox is boolean (0 or 1), map to a label or value -->
                {{ form[form.slug] ? 'Checked' : 'Unchecked' }}
              </template>
              
              <!-- Handle other input types -->
              <template v-else>
                {{ form[form.slug] }}
              </template>


            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<br>
<div 
  v-if="lkuData.status_lku === 2"
  class="mt-3 mb-3"
  style="border: 1px dashed red; color: red; padding: 8px;">
  <p><b>CATATAN :</b></p>
  {{ lkuData.alasan_revisi }}
</div>


        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: "ViewLkuComponent",
    data() {
      return {
        errorMessage: null,
        lkuData: null,
        isLoading: false,
      };
    },
    async created() {
      this.fetchLkuData();
    },
    methods: {
      formatRupiah(value) {
      if (value || value === 0) {
        return Number(value).toLocaleString('id-ID');
      }
      return null;
    },
      goBack() {
    this.$router.go(-1);  // This will navigate to the previous page.
  },
      async exportData() {
  try {
    const token = localStorage.getItem('token');
    // Check if the token exists
    if (!token) {
      this.errorMessage = 'Authentication token is missing. Please log in again.';
      console.error(this.errorMessage);
      return;
    }
    const tlku_id = this.$route.query.tlku_id;
    // Check if tlku_id is available
    if (!tlku_id) {
      this.errorMessage = 'ID is missing in the route query parameters.';
      console.error(this.errorMessage);
      return;
    }
    // Construct the URL for the PDF download
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/export/lapor-lku/${tlku_id}`;
    // Set Authorization header using Fetch API
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/pdf',
        'Authorization': `Bearer ${token}`,
      },
    });

    // Check if the response is okay
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Create a Blob from the PDF response
    const blob = await response.blob();

    // Create a URL for the Blob and open it in a new tab
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_blank'; // Open in new tab
    link.setAttribute('download', `exported_data_${tlku_id}.pdf`); // Set the filename
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.remove(); // Remove the link after download

    // Optionally display a success message
    this.successMessage = 'Data exported successfully!';

  } catch (apiError) {
    console.error('API request error:', apiError);
    this.errorMessage = 'Failed to export data. Please try again.';
  }
},
      async fetchLkuData() {
        this.isLoading = true; // Show loading indicator
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Authentication token is missing. Please log in again.');
          }
  
          const tlku_id = this.$route.query.tlku_id;
          if (!tlku_id) {
            throw new Error('tlku_id is missing in the route query parameters.');
          }
  
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/lapor-lku/${tlku_id}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
  
          if (response.data && response.data.data) {
            this.lkuData = response.data.data; // Ensure you access the correct data path
          } else {
            throw new Error('LKU data is not available.');
          }
        } catch (error) {
          this.errorMessage = error.response?.data?.message || error.message || 'Failed to fetch LKU data. Please try again later.';
          console.error(error);
        } finally {
          this.isLoading = false; // Hide loading indicator
        }
      },
    },
  };
  </script>s
  
  <style scoped>
  /* Add any styling needed here */
  </style>
  
<template>
  <div class="login">
    <Navbar />
   <LoginComponent />
    <FooterComponent />

  </div>
</template>

<script>

// @ is an alias to /src
import Navbar from '@/components/home/Navbar.vue'
import FooterComponent from '@/components/home/FooterComponent.vue'
import LoginComponent from '@/components/register/LoginComponent.vue'

export default {
  name: 'LoginView',
  components: {
    Navbar,
    LoginComponent,
    FooterComponent
    
  }
}
</script>

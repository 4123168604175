<template>
  <div class="verification">
    <Navbar />

    <div class="container">
      <!-- Display success or error message based on verification -->
      <b-toast id="toast-success" variant="success" solid>
        Verifikasi berhasil! Mengarahkan ke halaman login...
      </b-toast>

      <!-- Error message and resend button -->
      <div v-if="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
        <button v-if="showResendButton" class="btn btn-primary mt-3" @click="resendVerification">
          Kirim Ulang Email Verifikasi
        </button>
      </div>
    </div>
    <LoginComponent />
    <FooterComponent />
  </div>
</template>

<script>
import Navbar from '@/components/home/Navbar.vue';
import FooterComponent from '@/components/home/FooterComponent.vue';

import LoginComponent from '@/components/register/LoginComponent.vue'
import axios from 'axios';

export default {
  name: 'verificationView',
  components: {
    Navbar,
    LoginComponent,
    FooterComponent
  },
  data() {
    return {
      successMessage: '',
      errorMessage: '',
      showResendButton: false // Flag to show the resend button
    };
  },
  methods: {
    // Method to resend verification email
    async resendVerification() {
      const email = this.$route.query.email;
      const token = this.$route.query.token;

      if (email && token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/resend`, {
            params: { email, token }
          });

          if (response.data.status) {
            this.errorMessage = 'Email verifikasi baru telah dikirim.';
            this.showResendButton = false; // Hide resend button after successful resend
          } else {
            this.errorMessage = 'Gagal mengirim ulang email verifikasi.';
          }
        } catch (error) {
          console.error('Resend email error:', error);
          this.errorMessage = 'Terjadi kesalahan saat mengirim ulang email verifikasi. Mohon coba lagi.';
        }
      }
    }
  },
  // Created hook to handle email verification when the component is loaded
  async created() {
    const email = this.$route.query.email;
    const token = this.$route.query.token;

    if (email && token) {
      try {
        // Send verification request to the API
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/verification`, {
          params: { email, token }
        });

        // Check response using the correct key (response.data.status instead of success)
        if (response.status === 200 && response.data.status) {
          this.successMessage = 'Verifikasi berhasil!';
          this.$bvToast.show('toast-success');

          // Redirect to login after showing success message for 2 seconds
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        } else {
          this.errorMessage = 'Token tidak valid atau telah kedaluwarsa.';
          this.showResendButton = true;
        }
      } catch (error) {
        console.error('Verification error:', error);
        this.errorMessage = 'Terjadi kesalahan selama verifikasi. Mohon coba lagi.';
        this.showResendButton = true;
      }
    } else {
      this.errorMessage = 'Email atau token tidak tersedia di URL.';
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.alert {
  margin-top: 20px;
  text-align: center;
}

button {
  display: block;
  margin: 0 auto;
}
</style>

<template>
  <div>
    <div class="col">
      <h1 class="h3 ml-3 mb-1 text-gray-800">Tambah Data Usaha</h1>
    </div>

    <div class="container-fluid mt-3">
      <div class="row mt-3">
        <div class="col-xl-12 col-lg-7">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Data Usaha</h6>
            </div>
            <div class="card-body">
              <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <!-- Display Errors -->
                <div v-if="errors.length" class="alert alert-danger">
                  <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
                </div>

                <fieldset>
      <legend>Data Usaha</legend>
      
      <div class="form-group">
        <label for="tipe_usaha">Tipe Usaha <small style="color: red;">*</small></label>
        <b-form-select
          v-model="form.tipe_usaha_id"
          :options="types"
          id="tipe_usaha_id"
          required
        >
          <b-form-select-option :value="null" disabled>Pilih Tipe Usaha</b-form-select-option>
        </b-form-select>
      </div>

      <div class="form-group">
        <label for="nama_unit_usaha">Nama Usaha <small style="color: red;">*</small></label>
        <b-form-input
          v-model.trim="form.nama_usaha"
          type="text"
          id="nama_unit_usaha"
          placeholder="Masukan Nama Usaha"
          required
        ></b-form-input>
      </div>

      <div class="form-group">
        <label for="kabupaten_id">Pilih Kotamadya/Kabupaten <small style="color: red;">*</small></label>
        <b-form-select
          v-model="form.kabupaten_id"
          @change="fetchDistricts"
          :options="regencies"
          id="kabupaten_id"
          name="kabupaten_id"
          required
        >
          <b-form-select-option :value="null" disabled>Pilih Kabupaten</b-form-select-option>
        </b-form-select>
      </div>

      <div class="form-group">
        <label for="kecamatan_id">Kecamatan <small style="color: red;">*</small></label>
        <b-form-select
          v-model="form.kecamatan_id"
          @change="fetchVillages"
          :options="districts"
          id="kecamatan_id"
          name="kecamatan_id"
          required
        >
          <b-form-select-option :value="null" disabled>Pilih Kecamatan</b-form-select-option>
        </b-form-select>
      </div>

      <div class="form-group">
        <label for="kelurahan">Kelurahan <small style="color: red;">*</small></label>
        <b-form-select
          v-model="form.kelurahan_id"
          :options="villages"
          name="kelurahan_id"
          id="kelurahan_id"
          required
        >
          <b-form-select-option :value="null" disabled>Pilih Kelurahan</b-form-select-option>
        </b-form-select>
      </div>

                  <div class="form-group">
                    <label for="alamat">Alamat Usaha <small style="color: red;">*</small></label>
                    <textarea
                      class="form-control"
                      v-model.trim="form.alamat"
                      id="alamat"
                      placeholder="Tuliskan Alamat Usaha Anda (Contoh: Jl. Penganten Ali No 12 RT 009/RW 003)"
                      required
                      rows="5"
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="nopd">NOPD</label>
                    <input
                      v-model.trim="form.nopd"
                      type="number"
                      data-mask="############0"
                      class="form-control"
                      id="nopd"
                      maxlength="18"
                        @input="checkLength"
                      placeholder="Masukan NOPD"
                    >
                  </div>
                </fieldset>

                <!-- Data Penanggung Jawab -->
                <fieldset>
                  <legend>Data Penanggung Jawab</legend>
                  <div class="form-group">
                    <label for="penanggung_jawab">Nama Penanggung Jawab <small style="color: red;">*</small></label>
                    <b-form-input
                      v-model.trim="form.penanggung_jawab"
                      type="text"
                      id="penanggung_jawab"
                      placeholder="Masukan Nama Penanggung Jawab"
                      required
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label for="jabatan_penanggung_jawab">Jabatan Penanggung Jawab <small style="color: red;">*</small></label>
                    <b-form-input
                      v-model.trim="form.jabatan_penanggung_jawab"
                      type="text"
                      id="jabatan_penanggung_jawab"
                      placeholder="Masukan Jabatan Penanggung Jawab"
                      required
                    ></b-form-input>
                  </div>
                  <div class="form-group">
                    <label for="nohp_penanggung_jawab">No. HP Penanggung Jawab <small style="color: red;">*</small></label>
                    <input
                      v-model.trim="form.nohp_penanggung_jawab"
                      type="number"
                      data-mask="############0"
                      class="form-control"
                      id="nohp_penanggung_jawab"
                       @input="checknohp"
                      maxlenght="13"
                      placeholder="Masukan No. HP Penanggung Jawab"
                      required
                    >
                  </div>

                  <div class="form-group">
                    <label for="telpon_kantor">No. Telepon Kantor <small style="color: red;">*</small></label>
                    <input
                      v-model.trim="form.telpon_kantor"
                      type="number"
                       @input="checknok"
                      data-mask="############0"
                      class="form-control"
                      id="telpon_kantor"
                      maxlenght="13"
                      placeholder="Masukan No. Telepon Kantor"
                    >
                  </div>

                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      v-model.trim="form.email"
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Masukan Email"
                    >
                  </div>

                  <div class="form-group">
                    <label for="website">Website</label>
                    <input
                      v-model.trim="form.website"
                      type="url"
                      class="form-control"
                      id="website"
                      placeholder="Masukan Website contoh (https://website.com)"
                    >
                  </div>

                  <div class="form-group">
                    <label for="skala_usaha">Skala Usaha <small style="color: red;">*</small></label>
                    <select
                      v-model="form.skala_usaha"
                      class="form-control"
                      id="skala_usaha"
                      required
                    >
                      <option value="">Pilih Skala Usaha</option>
                      <option value="kecil">Kecil</option>
                      <option value="menengah">Menengah</option>
                      <option value="besar">Besar</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="luas_tanah">Luas Tanah (m<sup>2</sup>) <small style="color: red;">*</small></label>
                    <input
                      v-model.trim="form.luas_tanah"
                      type="number"
                      class="form-control decimal-input"
                      data-mask="###############0"
                      id="luas_tanah"
                      placeholder="Contoh: 300,45"
                    >
                  </div>

                  <div class="form-group">
                    <label for="luas_bangunan">Luas Bangunan (m<sup>2</sup>) <small style="color: red;">*</small></label>
                    <input
                      v-model.trim="form.luas_bangunan"
                      type="number"
                      class="form-control decimal-input"
                      data-mask="###############0"
                      id="luas_bangunan"
                      placeholder="Contoh: 300,45"
                    >
                  </div>

                  <div class="form-group">
                    <label for="instagram">Instagram</label>
                    <input
                      v-model.trim="form.instagram"
                      type="text"
                      class="form-control username"
                      id="instagram"
                      placeholder="Masukan Akun Media Sosial"
                    >
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Susunan Pengurus <small style="color: red;">*</small></legend>

                  <!-- Error message if no pengurus are added -->
                  <div v-if="pengurus.length === 0" style="color: red;">
                    Setidaknya satu jabatan dan satu nama pengurus harus diisi.
                  </div>

                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <!-- Jabatan input field -->
                        <td>
                          <div class="form-group">
                            <input
                              v-model="newPengurus.jabatan"
                              type="text"
                              class="form-control"
                              id="jabatan"
                              placeholder="Jabatan: Contoh (Direktur)"
                            />
                            <small v-if="jabatanValidation" style="color: red;">*Jabatan harus diisi*</small>
                          </div>
                        </td>

                        <!-- Nama Pengurus input field -->
                        <td>
                          <div class="form-group">
                            <input
                              v-model="newPengurus.nama_pengurus"
                              type="text"
                              class="form-control"
                              id="nama_pengurus"
                              placeholder="Nama Pengurus"
                            />
                            <small v-if="namaPengurusValidation" style="color: red;">*Nama pengurus harus diisi*</small>
                          </div>
                        </td>

                        <!-- Add Pengurus button -->
                        <td>
                          <button type="button" class="btn" @click="tambahPengurus">
                            <i class="bi bi-plus-square text-success" style="font-size: larger;"></i>
                          </button>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Display list of pengurus -->
                      <tr v-for="(pengurus, index) in pengurus" :key="index">
                        <td>{{ pengurus.jabatan }}</td>
                        <td>{{ pengurus.nama_pengurus }}</td>
                        <td>
                          <button type="button" class="btn btn-danger" @click="hapusPengurus(index)">
                            Hapus
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- Hidden inputs for submission -->
                  <div v-for="(pengurus, index) in pengurus" :key="index">
                    <input type="hidden" name="jabatan[]" :value="pengurus.jabatan" />
                    <input type="hidden" name="nama_pengurus[]" :value="pengurus.nama_pengurus" />
                  </div>
                </fieldset>

<fieldset>
    <legend>Data Tenaga Kerja</legend>

    <h5>TKI (Tenaga Kerja Indonesia)</h5>

    <div class="form-group">
      <div class="row">
        <div class="col-md-6">
          <label for="tki_pria">
            Jumlah Karyawan Pria <small style="color: red;">*</small>
          </label>
          <input
            type="text"
            v-model="form.tki_pria"
            v-mask="'###############0'"
            class="form-control"
            id="tki_pria"
            placeholder="Masukan Jumlah Karyawan Pria"
          />
        </div>
        <div class="col-md-6">
          <label for="tki_wanita">
            Jumlah Karyawan Wanita <small style="color: red;">*</small>
          </label>
          <input
            type="text"
            v-model="form.tki_wanita"
            v-mask="'###############0'"
            class="form-control"
            id="tki_wanita"
            placeholder="Masukan Jumlah Karyawan Wanita"
          />
        </div>
      </div>
    </div>

    <h5>TKA (Tenaga Kerja Asing)</h5>

    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          <label for="tka_pria">
            Jumlah Karyawan Pria <small style="color: red;">*</small>
          </label>
          <input
            type="text"
            v-model="form.tka_pria"
            v-mask="'###############0'"
            class="form-control"
            id="tka_pria"
            placeholder="Masukan Jumlah Karyawan Pria"
          />
        </div>
        <div class="col-sm-6">
          <label for="tka_wanita">
            Jumlah Karyawan Wanita <small style="color: red;">*</small>
          </label>
          <input
            type="text"
            v-model="form.tka_wanita"
            v-mask="'###############0'"
            class="form-control"
            id="tka_wanita"
            placeholder="Masukan Jumlah Karyawan Wanita"
          />
        </div>
      </div>
    </div>
  </fieldset>

  
  <fieldset>
    <legend>Data Legalitas Usaha</legend>

    <div class="form-group">
      <label for="jenis_izin">Jenis Izin <small style="color: red;">*</small></label>
      <select v-model="form.jenis_izin" class="form-control" id="jenis_izin" name="jenis_izin">
        <option value="">Pilih Jenis Izin</option>
        <option value="TDUP PTSP">TDUP PTSP</option>
        <option value="OSS RBA">OSS RBA</option>
      </select>
    </div>

    <div class="form-group">
      <label for="nomor_izin">Nomor Izin <small style="color: red;">*</small></label>
      <input
        v-model="form.nomor_izin"
        type="number"
        class="form-control"
        id="nomor_izin"
        name="nomor_izin"
        maxlength="18"
        @input="checkNiu"
        placeholder="Masukan Nomor Izin"
      />
    </div>

    <div class="form-group">
      <label for="tanggal_terbit_izin">Tanggal Terbit Izin <small style="color: red;">*</small></label>
      <input
        v-model="form.tanggal_terbit_izin"
       type="date"
        class="form-control col-sm-6"
        id="tanggal_terbit_izin"
        name="tanggal_terbit_izin"
        placeholder="Minimal tahun 2015"
        autocomplete="off"
      />
    </div>

    <div class="form-group">
      <label for="klasifikasi_resiko">Klasifikasi Resiko <small style="color: red;">*</small></label>
      <select v-model="form.klasifikasi_resiko" class="form-control" id="klasifikasi_resiko" name="klasifikasi_resiko">
        <option value="">Pilih Klasifikasi Resiko</option>
        <option value="rendah">Rendah</option>
        <option value="menengah rendah">Menengah Rendah</option>
        <option value="menengah tinggi">Menengah Tinggi</option>
        <option value="tinggi">Tinggi</option>
      </select>
    </div>

    <div class="form-group form-check">
      <input
        v-model="form.sertifikat_laik_sehat"
        type="checkbox"
        class="form-check-input"
        value="1"
        id="sertifikat_laik_sehat"
        name="sertifikat_laik_sehat"
      />
      <label class="form-check-label" for="sertifikat_laik_sehat">Sertifikat Laik Sehat</label>
    </div>

    <div class="form-group">
      <label for="masa_berlaku_laik_sehat">Masa Berlaku Sertifikat Laik Sehat:</label>
      <input
        v-model="form.masa_berlaku_laik_sehat"
      type="date"
        class="form-control col-sm-6"
        id="masa_berlaku_laik_sehat"
        name="masa_berlaku_laik_sehat"
        placeholder="dd/mm/yyyy"
        autocomplete="off"
         :disabled="!form.sertifikat_laik_sehat"
      />
    </div>

    <div class="form-group">
      <label for="slsFile" class="form-label">Upload SLS (PDF)</label>
      <b-form-file
        ref="slsFile"
        id="slsFile"
        name="sls_file"
        placeholder="Maksimal 1MB"
        accept=".pdf"
        @change="handleFileUpload('sls', $event)"
        :disabled="!form.sertifikat_laik_sehat"
      ></b-form-file>
      <div class="progress mt-3" v-if="uploadProgress.sls" style="display: block;">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{ width: uploadProgress.sls + '%' }"></div>
      </div>
      <div v-if="uploadedFiles.slsFile" class="alert alert-success mt-3">
        <button type="button" class="btn float-right" @click="removeFile('sls')">
          <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
        </button>
        Nama File: <span>{{ uploadedFiles.slsFile.name }}</span> <br />
        Ukuran File: <span>{{ (uploadedFiles.slsFile.size / 1024).toFixed(2) }} KB</span>
      </div>
    </div>

    <div class="form-group form-check">
      <input
        v-model="form.sertifikat_standar_usaha"
        type="checkbox"
        class="form-check-input"
        value="1"
        id="sertifikat_standar_usaha"
        name="sertifikat_standar_usaha"
      />
      <label class="form-check-label" for="sertifikat_standar_usaha">Sertifikat Standar Usaha (SSU)</label>
    </div>

    <div class="form-group">
      <label for="penerbit_ssu">Penerbit Sertifikat Standar Usaha (SSU):</label>
      <input
        v-model="form.penerbit_ssu"
        type="text"
        class="form-control"
        id="penerbit_ssu"
        name="penerbit_ssu"
        placeholder="Masukan Penerbit Sertifikat Standar Usaha"
         :disabled="!form.sertifikat_standar_usaha"
      />
    </div>

    


    <div class="form-group">
      <label for="masa_berlaku_ssu">Masa Berlaku Sertifikat Standar Usaha (SSU):</label>
      <input
        v-model="form.masa_berlaku_ssu"
        type="date"
        class="form-control col-sm-6"
        id="masa_berlaku_ssu"
        name="masa_berlaku_ssu"
        placeholder="dd/mm/yyyy"
        autocomplete="off"
         :disabled="!form.sertifikat_standar_usaha"
      />
    </div>

    <div class="form-group">
      <label for="ssuFile" class="form-label">Upload SSU (PDF)</label>
      <b-form-file
        ref="ssuFile"
        id="ssuFile"
        name="ssu_file"
        placeholder="Maksimal 1MB"
        accept=".pdf"
        @change="handleFileUpload('ssu', $event)"
        :disabled="!form.sertifikat_standar_usaha"
      ></b-form-file>
      <div class="progress mt-3" v-if="uploadProgress.ssu" style="display: block;">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{ width: uploadProgress.ssu + '%' }"></div>
      </div>
      <div v-if="uploadedFiles.ssuFile" class="alert alert-success mt-3">
        <button type="button" class="btn float-right" @click="removeFile('ssu')">
          <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
        </button>
        Nama File: <span>{{ uploadedFiles.ssuFile.name }}</span> <br />
        Ukuran File: <span>{{ (uploadedFiles.ssuFile.size / 1024).toFixed(2) }} KB</span>
      </div>
    </div>
  </fieldset>
  <fieldset>
  <legend>File Upload</legend>

  <!-- Perizinan File Upload -->
  <div class="form-group">
    <label for="izinFile">Upload File Perizinan (PDF) <small style="color: red;">*</small></label>
    <b-form-file
      ref="izinFile"
      id="izinFile"
      name="file_perizinan"
      placeholder="Maksimal 1MB"
      accept=".pdf"
      @change="handleFileUpload('izin', $event)"
      required
    ></b-form-file>
    
    <div class="progress mt-3" v-if="uploadProgress.izin">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :style="{ width: uploadProgress.izin + '%' }"
      ></div>
    </div>
    <div v-if="uploadedFiles.izinFile" class="alert alert-success mt-3">
      <button type="button" class="btn float-right" @click="removeFile('izin')">
        <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
      </button>
      Nama File: <span>{{ uploadedFiles.izinFile.name }}</span> <br />
      Ukuran File: <span>{{ (uploadedFiles.izinFile.size / 1024).toFixed(2) }} KB</span>
    </div>
  </div>

  <!-- Akta Pendirian File Upload -->
  <div class="form-group">
    <label for="aktaFile" class="form-label">Upload Akta Pendirian (PDF) <small style="color: red;">*</small></label>
    <b-form-file
      ref="aktaFile"
      id="aktaFile"
      placeholder="Maksimal 1MB"
      name="akta_pendirian_file"
      accept=".pdf"
      @change="handleFileUpload('akta', $event)"
      required
    ></b-form-file>
    <div class="progress mt-3" v-if="uploadProgress.akta">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :style="{ width: uploadProgress.akta + '%' }"
      ></div>
    </div>
    <div v-if="uploadedFiles.aktaFile" class="alert alert-success mt-3">
      <button type="button" class="btn float-right" @click="removeFile('akta')">
        <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
      </button>
      Nama File: <span>{{ uploadedFiles.aktaFile.name }}</span> <br />
      Ukuran File: <span>{{ (uploadedFiles.aktaFile.size / 1024).toFixed(2) }} KB</span>
    </div>
  </div>
</fieldset>


<!-- Loading spinner -->
<div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Tunggu Sebentar ...</p>
    </div>
 <!-- Submit Button -->
 <div class="form-group">
    <button :disabled="loading" @click="submitForm" type="submit" class="btn btn-primary">SIMPAN</button>
               
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateUsahaComponent",
  data() {
    return {
      loading: false, // Add this state
      form: {
        tipe_usaha_id: null,
        nama_usaha: "",
        kabupaten_id: null,
        kecamatan_id: null,
        kelurahan_id: null,
        alamat: "",
        nopd: "",
        penanggung_jawab: "",
        jabatan_penanggung_jawab: "",
        nohp_penanggung_jawab: "",
        telpon_kantor: "",
        email: "",
        website: "",
        skala_usaha: "",
        luas_tanah: "",
        luas_bangunan: "",
        instagram: "",
        jenis_izin: "",
        nomor_izin: "",
        tanggal_terbit_izin: "", // dd/mm/yyyy
        klasifikasi_resiko: "",
        sertifikat_laik_sehat: 0,
        masa_berlaku_laik_sehat: "", // dd/mm/yyyy
        sertifikat_standar_usaha: 0,
        penerbit_ssu: "",
        masa_berlaku_ssu: "", // dd/mm/yyyy
        tki_pria: "",
        tki_wanita: "",
        tka_pria: "",
        tka_wanita: "",
      },
      errors: [],
      types: [],
      regencies: [],
      districts: [],
      villages: [],
      selectedRegency: null,
      selectedDistrict: null,
      selectedVillage: null,
      pengurus: [],
      newPengurus: {
        jabatan: "",
        nama_pengurus: "",
      },
      jabatanValidation: false,
      namaPengurusValidation: false,
      uploadProgress: {
        sls: 0,
        ssu: 0,
        akta: 0,
        izin: 0,
      },
      uploadedFiles: {
        slsFile: '',
        ssuFile: '',
        aktaFile: '',
        izinFile: '',
      },
    };
  },
  methods: {
    checkLength(event) {
    const maxLength = 18;

    if (this.form.nopd && this.form.nopd.toString().length > maxLength) {
      this.form.nopd = this.form.nopd.toString().slice(0, maxLength);
    }

    // Ensure no input greater than max length
    event.target.value = this.form.nopd;
  },
  checknohp(event) {
    const maxLength = 13;

    if (this.form.nohp_penanggung_jawab && this.form.nohp_penanggung_jawab.toString().length > maxLength) {
      this.form.nohp_penanggung_jawab = this.form.nohp_penanggung_jawab.toString().slice(0, maxLength);
    }

    // Ensure no input greater than max length
    event.target.value = this.form.nohp_penanggung_jawab;
  },
  checknok(event) {
    const maxLength = 13;

    if (this.form.telpon_kantor && this.form.telpon_kantor.toString().length > maxLength) {
      this.form.telpon_kantor = this.form.telpon_kantor.toString().slice(0, maxLength);
    }

    // Ensure no input greater than max length
    event.target.value = this.form.telpon_kantor;
  },
  checkNiu(event) {
    const maxLength = 16;

    if (this.form.nomor_izin && this.form.nomor_izin.toString().length > maxLength) {
      this.form.nomor_izin = this.form.nomor_izin.toString().slice(0, maxLength);
    }

    // Ensure no input greater than max length
    event.target.value = this.form.nomor_izin;
  },
    formatDate(date) {
      if (!date) return "";
      // Check if the date is in yyyy-mm-dd format and convert it to dd/mm/yyyy
      if (date.includes("-")) {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }
      // If the date is already in dd/mm/yyyy format, return it as is
      return date;
    },
    handleFileUpload(field, event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedFiles[`${field}File`] = file;
        this.uploadProgress[field] = 0; // Initialize progress to 0
        console.log(`${field} file selected:`, file);
      }
    },
    async submitForm() {
  this.loading = true; // Set loading to true when submission starts
  const formData = new FormData();

  // Format date fields if needed
  this.form.tanggal_terbit_izin = this.formatDate(this.form.tanggal_terbit_izin);
  this.form.masa_berlaku_laik_sehat = this.formatDate(this.form.masa_berlaku_laik_sehat);
  this.form.masa_berlaku_ssu = this.formatDate(this.form.masa_berlaku_ssu);

  // Append form fields to FormData
  Object.keys(this.form).forEach((key) => {
    formData.append(key, this.form[key]);
  });

  // Append files to FormData
  formData.append("akta_pendirian_file", this.uploadedFiles.aktaFile);
  formData.append("sls_file", this.uploadedFiles.slsFile);
  formData.append("ssu_file", this.uploadedFiles.ssuFile);
  formData.append("file_perizinan", this.uploadedFiles.izinFile);

  // Append pengurus fields to FormData
  this.pengurus.forEach((item) => {
    formData.append("jabatan[]", item.jabatan);
    formData.append("nama_pengurus[]", item.nama_pengurus);
  });

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/unit-usaha`,
      formData,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Response data:", response.data);

    // Redirect to dashboard/unit-usaha with a success query parameter
    this.$router.push({ path: "/dashboard/unit-usaha", query: { success: true } });
  } catch (error) {
    if (error.response && error.response.data.errors) {
      this.errors = Object.values(error.response.data.errors).flat();
    } else {
      this.errors = ["An unexpected error occurred."];
    }
    console.error("Submission error:", error);
  } finally {
    this.loading = false; // Reset loading state after submission completes
  }
},

    async fetchTypes() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/tipe-usaha`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.types = response.data.data.map((type) => ({
          value: type.tipe_usaha_id,
          text: type.nama_tipeusaha,
        }));
      } catch (error) {
        console.error("Error fetching types:", error);
      }
    },
    async fetchRegencies() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/regencies/31`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        this.regencies = response.data.data.map((regency) => ({
          value: regency.kabupaten_id,
          text: regency.nama,
        }));
      } catch (error) {
        console.error("Error fetching regencies:", error);
      }
    },
    async fetchDistricts() {
      if (!this.form.kabupaten_id) return;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/districts/${this.form.kabupaten_id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.districts = response.data.data.map((district) => ({
          value: district.kecamatan_id,
          text: district.nama,
        }));
        this.form.kecamatan_id = null;
        this.villages = [];
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchVillages() {
      if (!this.form.kecamatan_id) return;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/villages/${this.form.kecamatan_id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.villages = response.data.data.map((village) => ({
          value: village.kelurahan_id,
          text: village.nama,
        }));
      } catch (error) {
        console.error("Error fetching villages:", error);
      }
    },
    tambahPengurus() {
      this.jabatanValidation = !this.newPengurus.jabatan;
      this.namaPengurusValidation = !this.newPengurus.nama_pengurus;

      if (!this.jabatanValidation && !this.namaPengurusValidation) {
        this.pengurus.push({ ...this.newPengurus });
        this.newPengurus.jabatan = "";
        this.newPengurus.nama_pengurus = "";
      }
    },
    hapusPengurus(index) {
      this.pengurus.splice(index, 1);
    },

    // Method to remove uploaded files
  removeFile(field) {
    // Reset the file input field
    this.$refs[`${field}File`].reset();
    // Clear the uploaded file data
    this.uploadedFiles[`${field}File`] = null;
    // Reset the upload progress
    this.uploadProgress[field] = 0;
    console.log(`${field} file removed.`);
  },
    
  },
  
  mounted() {
    this.fetchTypes();
    this.fetchRegencies();
  },
};
</script>
<style scoped>
/* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

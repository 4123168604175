<template>
  <div class="row">
    <div class="col">
      <h1 class="h3 ml-3 mb-1 text-gray-800">Master Data Unit {{ pageName }}</h1>
    </div>

    <!-- Begin Page Content -->
    <div class="container-fluid mt-3">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 class="m-0 font-weight-bold text-primary">Tabel Data Unit Usaha</h6>
          <a href="/dashboard/unit-usaha/create" class="btn btn-primary btn-md">
            <i class="bi bi-plus-lg"></i>
            <b> Tambah Data Usaha</b>
          </a>
        </div>
        <div class="card-body">
         <!-- Search Bar -->
<div class="mb-3 position-relative">
  <input
    type="text"
    v-model="searchQuery"
    placeholder="Search..."
    class="form-control pl-5"
  />
  <i class="bi bi-search position-absolute" style="top: 50%; left: 15px; transform: translateY(-50%);"></i>
</div>

          <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" cellspacing="0">
              <thead>
                <tr>
                  <th @click="sortBy('nama_unit_usaha')">Nama Unit Usaha</th>
                  <th @click="sortBy('penanggung_jawab')">Penanggung Jawab</th>
                  <th @click="sortBy('nopd')">NOPD</th>
                  <th @click="sortBy('tipe_usaha.tipe_usaha')">Kategori LKU</th>
                  <th>Status</th>
                  <th width="22%">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="unit in paginatedUnits" :key="unit.unit_usaha_id">
                  <td>{{ unit.nama_unit_usaha }}</td>
                  <td>{{ unit.penanggung_jawab }}</td>
                  <td>{{ unit.nopd }}</td>
                  <td>{{ unit.tipe_usaha.tipe_usaha }}</td>
                  <td>
                    <button :class="unit.status_unit_usaha === 1 ? 'btn btn-success' : 'btn btn-danger'">
                      {{ unit.status_unit_usaha === 1 ? 'Aktif' : 'Nonaktif' }} 
                    </button>

                </td>
                  <td>
              <div class="d-flex align-items-center">
                        <!-- Lapor LKU Button - only show if unit.status_unit_usaha !== 0 -->
                <router-link
                  v-if="unit.status_unit_usaha !== 0"
                  :to="{ path: '/dashboard/unit-usaha/lku', query: { id: unit.unit_usaha_id } }"
                  class="btn btn-success"
                  style="white-space: nowrap;"
                  @click="lku(unit.unit_usaha_id)"
                >
                  <i class="bi bi-cursor"></i> Lapor LKU
                </router-link>
                <!-- Warning Button with Reason - show if unit.status_unit_usaha === 0 -->
                <button
                  v-else
                  class="btn btn-warning"
                    :title="'Alasan Nonaktif : '+ unit.alasan"
                  @click="alasandokumen(unit.unit_usaha_id, unit.file_nonactive)"
                >
                  <i class="bi bi-exclamation-circle"></i>
                </button>



<b-dropdown
  id="dropdownMenuLink"
  variant="info"
  class="ml-3"
  right
  no-caret
>
  <template #button-content>
    <i class="bi bi-gear fa-sm fa-lg"></i> Setting
  </template>

  <b-dropdown-item
    @click="lihatUnit(unit.unit_usaha_id)"
    class="text-info"
  >
    <i class="bi bi-zoom-in text-info mr-2"></i> Lihat Data
  </b-dropdown-item>

  <!-- Ubah Data - only show if unit.status_unit_usaha !== 0 -->
  <b-dropdown-item
    v-if="unit.status_unit_usaha !== 0"
    @click="editUnit(unit.unit_usaha_id)"
    class="text-warning"
  >
    <i class="bi bi-pencil mr-2 text-warning"></i> Ubah Data
  </b-dropdown-item>

  <!-- Nonaktifkan - only show if unit.status_unit_usaha !== 0 -->
  <b-dropdown-item
    v-if="unit.status_unit_usaha !== 0"
    @click="showDeleteModal(unit.unit_usaha_id)"
    class="text-danger"
  >
    <i class="bi bi-trash text-danger mr-2"></i> Nonaktifkan
  </b-dropdown-item>
</b-dropdown>

                    </div>
                  </td>
                </tr>
                <tr v-if="!units.length">
                  <td colspan="6" class="text-center">Belum ada data unit usaha</td>
                </tr>
              </tbody>
            </table>

            <!-- Pagination -->
            <nav aria-label="Page navigation">
              <ul class="pagination mt-3">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="currentPage--">Previous</a>
                </li>
                <li
                  class="page-item"
                  v-for="page in totalPages"
                  :key="page"
                  :class="{ active: currentPage === page }"
                >
                  <a class="page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <a class="page-link" href="#" @click.prevent="currentPage++">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <b-modal
    id="deleteModal"
    ref="deleteModal"
    :title="`Nonaktifkan Unit Usaha ${unitToDeleteName}`"
    hide-footer
  >
    <!-- Success Message Display -->
    <b-alert
      v-if="successMessage"
      variant="success"
      dismissible
      @dismissed="successMessage = ''"
      class="mt-3"
    >
      {{ successMessage }}
    </b-alert>

    <form @submit.prevent="submitDeletion">
      <!-- Alasan Input -->
      <div class="form-group">
        <label for="alasan">Alasan <small style="color: red;">*</small></label>
        <textarea
          class="form-control"
          v-model.trim="alasan"
          id="alasan"
          placeholder="Tuliskan Alasan Anda"
          required
          rows="5"
        ></textarea>
      </div>

      <!-- Pernyataan Penutupan File Upload -->
      <div class="form-group">
        <label for="pernyataan_penutupan" class="form-label">Pernyataan Penutupan <B>(Max 1MB) </B></label>
        <b-form-file
          ref="pernyataan_penutupan"
          id="pernyataan_penutupan"
          name="pernyataan_penutupan"
          placeholder="Upload dokumen alasan nonaktif"
          accept=".pdf"
          @change="handleFileUpload($event)"
        ></b-form-file>

       

        <!-- Uploaded File Display -->
        <div v-if="uploadedFiles.pernyataan_penutupan" class="alert alert-success mt-3">
          <button type="button" class="btn float-right" @click="removeFile('pernyataan')">
            <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
          </button>
          Nama File: <span>{{ uploadedFiles.pernyataan_penutupan.name }}</span> <br />
          Ukuran File: <span>{{ (uploadedFiles.pernyataan_penutupan.size / 1024).toFixed(2) }} KB</span>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group mt-2">
        <b-button type="submit" variant="primary">SIMPAN</b-button>
      </div>
    </form>
  </b-modal>
    <!-- /.container-fluid -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "UsahaComponent",
  data() {
    return {
      url:`${process.env.VUE_APP_API_BASE_URL}`,
      pageName: "Usaha",
      units: [],
      searchQuery: "",
      sortKey: "",
      sortOrders: {},
      currentPage: 1,
      itemsPerPage: 10,
      alasan: '',
      uploadProgress: { pernyataan: 0 },
      uploadedFiles: { pernyataan_penutupan: null },
      unitToDelete: null, // Store the ID of the unit to delete
      successMessage: '',
    };
  },
  computed: {
    filteredUnits() {
  return this.units.filter((unit) => {
    const searchQuery = this.searchQuery.toLowerCase();
    
    // Check unit.tipe_usaha.tipe_usaha
    const tipeUsaha = unit.tipe_usaha && unit.tipe_usaha.tipe_usaha 
      ? unit.tipe_usaha.tipe_usaha.toLowerCase() 
      : '';

    // Combine all searchable values into a single array
    const searchableValues = [
      ...Object.values(unit).map(value => String(value).toLowerCase()),
      tipeUsaha
    ];

    // Check if any of the values include the search query
    return searchableValues.some((value) => value.includes(searchQuery));
  });
},
    unitToDeleteName() {
    // Return the unit name if unitToDelete exists, otherwise return an empty string
    return this.unitToDelete && this.unitToDelete.nama_unit_usaha ? this.unitToDelete.nama_unit_usaha : '';
  },
    sortedUnits() {
      if (!this.sortKey) {
        return this.filteredUnits;
      }

      let sortedArray = [...this.filteredUnits];

      return sortedArray.sort((a, b) => {
        let result = 0;
        const sortOrder = this.sortOrders[this.sortKey] || 1;

        if (a[this.sortKey] < b[this.sortKey]) {
          result = -1;
        } else if (a[this.sortKey] > b[this.sortKey]) {
          result = 1;
        }

        return result * sortOrder;
      });
    },
    paginatedUnits() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.sortedUnits.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.sortedUnits.length / this.itemsPerPage);
    },
  },
  methods: {
    alasandokumen(unitUsahaId, fileNonactive) {
    const fileUrl = `${process.env.VUE_APP_API_BASE_URL}/storage/${fileNonactive}`;
    window.open(fileUrl, '_blank'); // Open the file in a new tab
  },
    sortBy(key) {
      this.sortOrders[key] = this.sortOrders[key] === 1 ? -1 : 1;
      this.sortKey = key;
    },
    async fetchUnits() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/all-unit-usaha`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        this.units = response.data.data; // Access the `data` field
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    },
    editUnit(id) {
  // Navigate to the edit route with the provided id
  this.$router.push({ path: `/dashboard/unit-usaha/edit`, query: { id } });
},
lihatUnit(id) {
  // Navigate to the edit route with the provided id
  this.$router.push({ path: `/dashboard/unit-usaha/view`, query: { id } });
},
lku(id) {
  // Navigate to the edit route with the provided id
  this.$router.push({ path: `/dashboard/unit-usaha/lku`, query: { id } });
},
    showDeleteModal(id) {
    // Find the unit by ID and set it to unitToDelete
    this.unitToDelete = this.units.find(unit => unit.unit_usaha_id === id); 
    this.$refs.deleteModal.show(); // Show the modal
  },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedFiles.pernyataan_penutupan = file;
        // Simulate upload progress for demonstration purposes
        this.uploadProgress.pernyataan = 0;
        const interval = setInterval(() => {
          if (this.uploadProgress.pernyataan >= 100) {
            clearInterval(interval);
          } else {
            this.uploadProgress.pernyataan += 10;
          }
        }, 200);
      }
    },
    removeFile() {
      this.uploadedFiles.pernyataan_penutupan = null;
      this.uploadProgress.pernyataan = 0;
    },
    async submitDeletion() {
    // Ensure that `unitToDelete` is set and the required data is available
    if (!this.unitToDelete || !this.alasan) {
      console.error('Alasan and unitToDelete are required.');
      return;
    }

    // Create a FormData object to handle the file upload
    const formData = new FormData();
    formData.append('_method', 'PATCH'); // Use PATCH method for updating
    formData.append('alasan', this.alasan); // Add alasan to form data

    // Append the uploaded file if it exists
    if (this.uploadedFiles.pernyataan_penutupan) {
      formData.append('pernyataan_penutupan', this.uploadedFiles.pernyataan_penutupan);
    }

    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('token');

      // Make the API request to deactivate the unit
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/unit-usaha/${this.unitToDelete.unit_usaha_id}`, // Use the correct endpoint with ID
        formData, // Send FormData
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data', // Ensure correct content type for file uploads
          },
        }
      );

      // Handle the response (e.g., refresh unit list, show success message)
      console.log('Deletion successful:', response.data);
      // Refresh units list or update UI as needed
      this.fetchUnits(); // Fetch updated units list if needed
        // Optionally, notify the user of success
        this.$bvToast.toast('Data berhasil dinonaktifkan', {
          title: 'BERHASIL',
          variant: 'danger',
          solid: true
        });

    } catch (error) {
      // Handle errors appropriately
      console.error('Error during deletion:', error);
    } finally {
      // Hide the modal and reset form data
      this.$refs.deleteModal.hide();
      this.alasan = '';
      this.uploadedFiles.pernyataan_penutupan = null;
      this.uploadProgress.pernyataan = 0;
    }
  },
  },
  mounted() {
  this.fetchUnits();

  // Check if the success query parameter is present
  if (this.$route.query.success) {
    this.$bvToast.toast('Unit usaha berhasil ditambahkan', {
      title: 'BERHASIL',
      variant: 'success',
      solid: true
    });
    // Refresh the data after showing the toast without reloading the browser
    setTimeout(() => {
      this.$router.replace({ path: '/dashboard/unit-usaha', query: {} });
      this.fetchUnits(); // Call the fetchUnits method again to refresh data
    }, 2000); // Delay to allow toast to be visible
  } 

  // Check if the updated query parameter is present
  if (this.$route.query.updated) {
    this.$bvToast.toast('Unit usaha berhasil diubah', {
      title: 'BERHASIL',
      variant: 'success',
      solid: true
    });
    // Refresh the data after showing the toast without reloading the browser
    setTimeout(() => {
      this.$router.replace({ path: '/dashboard/unit-usaha', query: {} });
      this.fetchUnits(); // Call the fetchUnits method again to refresh data
    }, 2000); // Delay to allow toast to be visible
  }
},


};
</script>

<style scoped>
/* Scoped or global styles */

.table-responsive {
  overflow-x: auto;
  border: 1px solid #ddd; /* Light border around the table container */
  border-radius: 0.375rem; /* Rounded corners for the border */
}

.table {
  margin-bottom: 0; /* Removes default margin */
  background-color: #fff; /* Ensure the background color is white */
}

.table thead th {
  background-color: #f8f9fa; /* Light background for headers */
  text-align: center; /* Center-align text in headers */
}

.table tbody tr:hover {
  background-color: #f1f1f1; /* Light gray background on row hover */
}

.table th,
.table td {
  padding: 0.75rem; /* Consistent padding for cells */
  vertical-align: middle; /* Align text in the middle vertically */
  border-top: 1px solid #dee2e6; /* Border color for cells */
}

.table-bordered {
  border-collapse: collapse; /* Ensures borders are combined */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6; /* Border color for cells */
}

.table-responsive-md {
  overflow-x: auto;
}
</style>

<template>
  <div class="row">
    <div class="col">
      <h1 class="h3 mb-1 ml-3 text-gray-800">Master Data {{ pageName }}</h1>
      <h2 class="h3 mb-0 text-gray-800"></h2>
    </div>
    <div class="container-fluid mt-3">
    <b-card class="shadow mb-4">
      <b-card-header class="d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Data Perusahaan</h6>
      </b-card-header>
      <b-card-body>
        <h3>{{ perusahaan.nama_perusahaan.toUpperCase() }}</h3>
        <p>{{ perusahaan.alamat }}</p>

        <!-- Use overflow-x-scroll class for horizontal scroll on smaller screens -->
        <div class="overflow-x-scroll">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Nama Penanggung Jawab</th>
                <th>Jabatan</th>
                <th>Status Penanaman Modal</th>
                <th>NIB (Nomor Induk Berusaha)</th>
                <th>Tanggal Terbit NIB</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ perusahaan.penanggung_jawab }}</td>
                <td>{{ perusahaan.jabatan_penanggung_jawab }}</td>
                <td>{{ perusahaan.status_penanaman_modal }}</td>
                <td>{{ perusahaan.nib }}</td>
                <td>{{ perusahaan.tanggal_terbit_nib }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-body>
    </b-card>
  </div>

    <div class="container-fluid mt-3">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Detail Perusahaan</h6>
        </div>
        <div class="card-body">
          <div v-if="!perusahaan.alamat">
            <h5>Formulir Data Perusahaan</h5>
          </div>

          <div v-if="errors.length">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
              <button type="button" class="btn-close" @click="clearErrors"></button>
            </div>
          </div>

          <form @submit.prevent="submitForm" id="form_perusahaan">
            <div class="form-group">
              <label for="name">Nama Perusahaan <small style="color: red;">*</small></label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                placeholder="Masukan Nama Perusahaan"
                v-model="form.nama_perusahaan"
                required
                readonly
              />
            </div>

            <div class="form-group">
              <label for="alamat">Alamat Perusahaan <small style="color: red;">*</small></label>
              <textarea
                class="form-control"
                id="alamat"
                name="alamat"
                placeholder="Masukan Alamat Perusahaan"
                v-model="form.alamat"
                required
              ></textarea>
            </div>

            <div class="form-group">
              <label for="penanggung_jawab">Nama Penanggung Jawab <small style="color: red;">*</small></label>
              <input
                type="text"
                class="form-control"
                id="penanggung_jawab"
                placeholder="Masukan Nama Penanggung Jawab"
                name="penanggung_jawab"
                v-model="form.penanggung_jawab"
                required
              />
            </div>

            <div class="form-group">
              <label for="jabatan_penanggung_jawab">Jabatan Penanggung Jawab <small style="color: red;">*</small></label>
              <input
                type="text"
                class="form-control"
                id="jabatan_penanggung_jawab"
                placeholder="Masukan Jabatan Penanggung Jawab"
                name="jabatan_penanggung_jawab"
                v-model="form.jabatan_penanggung_jawab"
                required
              />
            </div>

            <div class="form-group">
              <label for="status_penanaman_modal">Status Penanaman Modal <small style="color: red;">*</small></label>
              <select
                class="form-control"
                id="penanaman_modal"
                name="penanaman_modal"
                v-model="form.status_penanaman_modal"
                required
              >
                <option value="">Pilih Status</option>
                <option value="PMA ( Penanaman Modal Asing )">PMA (Penanaman Modal Asing)</option>
                <option value="PMDN ( Penanaman Modal Dalam Negri )">PMDN (Penanaman Modal Dalam Negeri)</option>
              </select>
            </div>

            <div class="form-group">
              <label for="nib">Nomor Induk Berusaha (NIB) <small style="color: red;">*</small></label>
              <input
                type="text"
                class="form-control"
                id="nib"
                name="nib"
                placeholder="Masukan Nomor Induk Berusaha (NIB)"
                v-model="form.nib"
                required
                readonly
              />
            </div>

          
            <div class="form-group">
              <label for="tanggal_terbit_nib">Tanggal Terbit NIB <small style="color: red;">*</small></label>
              <input
                type="date"
                class="form-control"
                id="tanggal_terbit_nib"
                name="tanggal_terbit_nib"
                v-model="form.tanggal_terbit_nib"
                required
              />
            </div>

            <button type="submit" class="btn btn-primary mt-3">SIMPAN</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: "PerusahaanComponent",
  data() {
    return {
      pageName: "Perusahaan",
      perusahaan: {
        nama_perusahaan: "",
        alamat: "",
        penanggung_jawab: "",
        jabatan_penanggung_jawab: "",
        status_penanaman_modal: "",
        nib: "",
        tanggal_terbit_nib: "",
      },
      form: {
        nama_perusahaan: "",
        alamat: "",
        penanggung_jawab: "",
        jabatan_penanggung_jawab: "",
        status_penanaman_modal: "",
        nib: "",
        tanggal_terbit_nib: "",
      },
      errors: [],
    };
  },
 
  methods: {
    async fetchData() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/perusahaan`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      this.perusahaan = response.data.data;


     

      
      this.form = { ...this.perusahaan };
       // Convert date from DD-MM-YYYY to YYYY-MM-DD for the input field
       if (this.perusahaan.tanggal_terbit_nib) {
        const [day, month, year] = this.perusahaan.tanggal_terbit_nib.split('-');
        this.form.tanggal_terbit_nib = `${year}-${month}-${day}`;
      }
     
    } catch (error) {
      console.error('Error fetching company data:', error.response ? error.response.data : error.message);
      this.errors.push('Failed to fetch company data.');
    }
  },

  async submitForm() {
    try {
      // Convert date back to DD-MM-YYYY format for the API
      const [year, month, day] = this.form.tanggal_terbit_nib.split('-');
      this.form.tanggal_terbit_nib = `${day}-${month}-${year}`;
      
      const response = await axios.patch(`${process.env.VUE_APP_API_BASE_URL}/api/update-perusahaan`, this.form, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      
      console.log('Data updated successfully:', response.data);
      
      // Fetch the updated data
      await this.fetchData();
      
      // Optionally, notify the user of success
      this.$bvToast.toast('Data Perusahaan berhasil di ubah', {
        title: 'BERHASIL',
        variant: 'success',
        solid: true
      });
      
    } catch (error) {
      console.error('Error updating company data:', error.response ? error.response.data : error.message);
      if (error.response && error.response.data && error.response.data.errors) {
        this.errors = Object.values(error.response.data.errors).flat();
      } else {
        this.errors.push('Failed to update company data.');
      }
    }
  },
    
    clearErrors() {
      this.errors = [];
    },
    editData() {
      // Logic for editing data if necessary
    },
  },
  mounted() {
    this.fetchData();
    
  },
};
</script>

<style scoped>
.table-responsive {
  margin-top: 20px;
}

.btn-close {
  background-color: transparent;
  border: none;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.card-body h3 {
  font-weight: bold;
}

.card-body p {
  margin-top: 10px;
}

.form-group label {
  font-weight: bold;
}

.small-text {
  color: red;
}

.mt-3 {
  margin-top: 30px;
}

@mixin overflow-x($value) {
  overflow-x: $value !important;
}


.overflow-x-auto {
  @include overflow-x(auto);
}

.overflow-x-hidden {
  @include overflow-x(hidden);
}

.overflow-x-visible {
  @include overflow-x(visible);
}

.overflow-x-scroll {
  @include overflow-x(scroll);
}

</style>

<template>
  <div>
     
         <!-- Experience Start -->
         <div class="experience" id="experience">
            
            <div class="container">
                <header class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                   
                    <h1><strong>Panduan LKU</strong></h1>
                    <h5>Berikut ini adalah panduan dalam pembuatan Laporan Kegiatan Usaha</h5>
                </header>
                <div class="timeline mt-5">
                    <div class="timeline-item left wow slideInLeft" data-wow-delay="0.1s">
                        <div class="timeline-text">
                            <div class="timeline-date">LANGKAH PERTAMA</div>
                            <h2 class="mb-2">PROSES PENDAFTARAN</h2>
                            <ul style="font-weight: 400;">
                                <li>Klik menu pendaftaran.</li>
                                <li>Masukkan email.</li>
                                <li>Masukkan nomor NIB.</li>
                                <li>Masukkan nama perusahaan Anda.</li>
                                <li>Buat password akun Anda.</li>
                                <li>Konfirmasi password Anda.</li>
                                <li>Login menggunakan akun yang Anda buat.</li>
                            </ul>
                        </div>

                    </div>
                    <div class="timeline-item right wow slideInRight" data-wow-delay="0.1s">
                        <div class="timeline-text">
                            <div class="timeline-date">LANGKAH KEDUA</div>
                            <h2 class="mb-2">ISI DATA PERUSAHAAN</h2>
                           
                            <p>Lengkapi formulir dengan memasukkan data perusahaan Anda, termasuk nama perusahaan, alamat, nomor telepon, dan informasi kontak lainnya. Pastikan semua data yang dimasukkan akurat dan terbaru untuk memudahkan proses verifikasi.</p>
                        </div>
                    </div>
                    <div class="timeline-item left wow slideInLeft" data-wow-delay="0.1s">
                        <div class="timeline-text">
                            <div class="timeline-date">LANGKAH KETIGA</div>
                            <h2 class="mb-2">BUAT DATA UNIT USAHA</h2>
                           
                            <p>Lengkapi formulir dengan memasukkan data unit usaha Anda, termasuk nama unit usaha, jenis usaha, lokasi, dan rincian lainnya yang relevan. Pastikan semua informasi yang dimasukkan akurat dan lengkap untuk memudahkan proses verifikasi dan administrasi.</p>
                        </div>
                    </div>
                    <div class="timeline-item right wow slideInRight" data-wow-delay="0.1s">
                        <div class="timeline-text">
                            <div class="timeline-date">LANGKAH KEMPAT</div>
                            <h2 class="mb-2">LAPOR KEGIATAN USAHA ANDA</h2>
                           
                            <p>Laporkan kegiatan usaha Anda setiap semester dengan mengisi formulir laporan yang tersedia. Sertakan informasi lengkap mengenai aktivitas bisnis, pencapaian, dan tantangan yang dihadapi selama periode tersebut. Pastikan laporan Anda mencakup data yang akurat dan relevan untuk memudahkan proses evaluasi dan pemantauan.</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        <!-- Job Experience End -->
        
  </div>
</template>
<script>
export default {
    name: "TimelineComponent"
}
</script>

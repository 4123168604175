<template>
  <div>
   

    <div class="col">
      <h1 class="h3 ml-3 mb-1 text-gray-800">Data {{ pageName }}</h1>
    </div>

    <!-- Begin Page Content -->
    <div class="container-fluid mt-3">
      <!-- DataTales Example -->
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex justify-content-between align-items-center">
          <h6 class="m-0 font-weight-bold text-primary">
            Tabel Laporan {{ unitUsaha ? unitUsaha.nama_unit_usaha : '' }}
          </h6>
          <router-link
  :to="{ path: `/dashboard/unit-usaha/lku/create?id=${this.$route.query.id}` }"
  class="btn btn-primary btn-md"
  style="white-space: nowrap; display: inline-block;"
>
  <i class="bi bi-plus-lg"></i>
  <b> Tambah LKU</b>
</router-link>


        </div>
        <div class="card-body">
        
<!-- Search Bar -->
<div class="mb-3 position-relative">
  <input
    type="text"
    v-model="searchQuery"
    placeholder="Search..."
    class="form-control pl-5"
  />
  <i class="bi bi-search position-absolute" style="top: 50%; left: 15px; transform: translateY(-50%);"></i>
</div>
<div class="table-responsive">
  <table class="table table-bordered" width="100%" cellspacing="0">
    <thead>
      <tr>
        <th>Tahun Pelaporan</th>
        <th>Periode</th>
        <th>Tanggal Pelaporan</th>
        <th>Status</th>
        <th>Perbaikan</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="lku in paginatedLkus" :key="lku.tlku_id">
        <td>{{ lku.tahun_pelaporan }}</td>
        <td>Semester {{ lku.semester }}</td>
        <td>{{ lku.tanggal_pelaporan }}</td>
        <td>
          <span v-if="lku.status_lku === 0" class="badge rounded-pill bg-primary">Draft</span>
          <span v-else-if="lku.status_lku === 1" class="badge rounded-pill bg-success">Disetujui</span>
          <span v-else-if="lku.status_lku === 2" class="badge rounded-pill bg-danger">Revisi</span>
        </td>
        <td>Perbaikan ke {{ lku.perbaikan }}</td>
        <td>
          <div class="d-flex align-items-center">
            <div
              v-if="!alreadyApproved(lku) && lku.status_lku !== 1  && lku.status_lku !== 2"
              class="text-success mx-2 link-alert"
              @click="submitLku(lku.tlku_id)"
              title="Ajukan Laporan"
            >
              <i class="bi bi-cursor"></i>
            </div>
            <div class="text-info mx-2" @click="viewLku(lku.tlku_id)" title="Lihat Data">
              <i class="bi bi-zoom-in"></i>
            </div>
            <div v-if="lku.status_lku === 0" class="text-warning mx-2" @click="editLku(lku.tlku_id)" title="Ubah Data">
    <i class="bi bi-pencil"></i>
</div>

            <div 
              v-if="!alreadyApproved(lku) && lku.status_lku !== 1 && lku.status_lku !== 2"
              class="text-danger mx-2" @click="deleteLku(lku.tlku_id)" title="Hapus LKU">
              <i class="bi bi-trash"></i>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Pagination -->
  <nav aria-label="Page navigation">
    <ul class="pagination mt-3">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="#" @click.prevent="currentPage--">Previous</a>
      </li>
      <li
        class="page-item"
        v-for="page in totalPages"
        :key="page"
        :class="{ active: currentPage === page }"
      >
        <a class="page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" @click.prevent="currentPage++">Next</a>
      </li>
    </ul>
  </nav>
</div>

        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LkuComponent",
  data() {
    return {
      loading: false,
    
      searchQuery: "",
      sortKey: "",
      sortOrders: {},
      currentPage: 1,
      itemsPerPage: 5,
      successMessage: "Data saved successfully!",
      pageName: "Laporan Keuangan Usaha",
      unitUsaha: { nama_unit_usaha: "Unit Contoh" },
      lkus: [],
      unitToDelete: null, // Store the ID of the unit to delete
    };
  },

  async created() {
    await this.fetchUnits(); // Fetch units on creation
  },
  computed: {
  filteredLkus() {
    return this.lkus.filter((lku) => {
      const searchQuery = this.searchQuery.toLowerCase();

      // Check if lku.tipe_usaha.tipe_usaha exists
      const tipeUsaha = lku.tipe_usaha && lku.tipe_usaha.tipe_usaha 
        ? lku.tipe_usaha.tipe_usaha.toLowerCase() 
        : '';

      // Combine all searchable values into a single array
      const searchableValues = [
        ...Object.values(lku).map(value => String(value).toLowerCase()),
        tipeUsaha
      ];

      // Check if any of the values include the search query
      return searchableValues.some((value) => value.includes(searchQuery));
    });
  },

  lkuToDeleteName() {
    // Return the LKU name if lkuToDelete exists, otherwise return an empty string
    return this.lkuToDelete && this.lkuToDelete.nama_lku ? this.lkuToDelete.nama_lku : '';
  },

  sortedLkus() {
    if (!this.sortKey) {
      return this.filteredLkus;
    }

    let sortedArray = [...this.filteredLkus];

    return sortedArray.sort((a, b) => {
      let result = 0;
      const sortOrder = this.sortOrders[this.sortKey] || 1;

      if (a[this.sortKey] < b[this.sortKey]) {
        result = -1;
      } else if (a[this.sortKey] > b[this.sortKey]) {
        result = 1;
      }

      return result * sortOrder;
    });
  },

  paginatedLkus() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.sortedLkus.slice(start, end);
  },

  totalPages() {
    return Math.ceil(this.sortedLkus.length / this.itemsPerPage);
  },
},


  methods: {
    async fetchUnits() {
      try {
        const token = localStorage.getItem('token');
        const id = this.$route.query.id;

        if (!id) {
          console.error('ID is missing in the route query parameters.');
          return;
        }

        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/unit-usaha/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        this.lkus = response.data.data.laporan_lku;
        this.unitUsaha = { nama_unit_usaha: response.data.data.nama_unit_usaha };
      } catch (error) {
        console.error("Failed to fetch LKU data", error);
      }
    },

    showToast(message, title) {
    this.$bvToast.toast(message, {
      title: title,
      variant: 'success',
      solid: true,
    });
 
    const currentRoute = this.$route.fullPath;
    const targetRoute = `/dashboard/unit-usaha/lku?id=${this.$route.query.id}`;

    // Only navigate if the current route is different from the target route
    if (currentRoute !== targetRoute) {
      setTimeout(() => {
        this.$router.replace({ path: '/dashboard/unit-usaha/lku', query: { id: this.$route.query.id } });
        this.fetchUnits(); // Refresh data
      }, 2000);
    } else {
      setTimeout(() => {
        this.fetchUnits(); // Just refresh data without navigation
      }, 2000);
    }
  },

  showDanger(message, title) {
    this.$bvToast.toast(message, {
      title: title,
      variant: 'danger',
      solid: true,
    });
     const currentRoute = this.$route.fullPath;
    const targetRoute = `/dashboard/unit-usaha/lku?id=${this.$route.query.id}`;

    // Only navigate if the current route is different from the target route
    if (currentRoute !== targetRoute) {
      setTimeout(() => {
        this.$router.replace({ path: '/dashboard/unit-usaha/lku', query: { id: this.$route.query.id } });
        this.fetchUnits(); // Refresh data
      }, 2000);
    } else {
      setTimeout(() => {
        this.fetchUnits(); // Just refresh data without navigation
      }, 2000);
    }
  },

    alreadyApproved(lku) {
      return lku.status_lku === 1;
    },

    async submitLku(tlku_id) {
  this.loading = true; // Start loading state
  try {
    const token = localStorage.getItem('token');

    // Send PATCH request to the API
    const response = await axios.patch(`${process.env.VUE_APP_API_BASE_URL}/api/lapor-lku/${tlku_id}`, {}, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    // Display success message
    this.showToast(`LKU berhasil diajukan. ID: ${tlku_id}`, 'BERHASIL');
    
    console.log('Response data:', response.data);
    
  } catch (error) {
    // Improved error handling
    if (error.response) {
      console.error('Error response data:', error.response.data);
      alert(`Error: ${error.response.data.message || 'Failed to submit LKU'}`);
    } else if (error.request) {
      console.error('Error request:', error.request);
      alert('No response from the server. Please try again later.');
    } else {
      console.error('Error message:', error.message);
      alert('An error occurred while submitting LKU.');
    }
  } finally {
    this.loading = false; // Reset loading state
  }
},


    async deleteLku(tlku_id) {
      try {
        const token = localStorage.getItem('token');

        // Send DELETE request to the API
        const response = await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/lapor-lku/${tlku_id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        // Display success message
        this.showDanger(`LKU berhasil dihapus. ID: ${tlku_id}`, 'BERHASIL');
        
        console.log('Response data:', response.data);
        
      } catch (error) {
        // Improved error handling
        if (error.response) {
          console.error('Error response data:', error.response.data);
          alert(`Error: ${error.response.data.message || 'Failed to delete LKU'}`);
        } else if (error.request) {
          console.error('Error request:', error.request);
          alert('No response from the server. Please try again later.');
        } else {
          console.error('Error message:', error.message);
          alert('An error occurred while deleting LKU.');
        }
      }
    },

    viewLku(tlku_id) {
    
    // Redirect to the view page with the provided tlku_id
    this.$router.push({
      path: '/dashboard/unit-usaha/lku/view',
      query: { tlku_id: tlku_id }
    });
  },

    editLku(tlku_id) {
      // Redirect to the view page with the provided tlku_id
    this.$router.push({
      path: '/dashboard/unit-usaha/lku/edit',
      query: { tlku_id: tlku_id }
    });
    },
  },

  mounted() {
    if (this.$route.query.success) {
      this.showToast('LKU berhasil ditambahkan', 'BERHASIL');
    }

    if (this.$route.query.updated) {
      this.showToast('LKU berhasil diubah', 'BERHASIL');
    }
  },
};
</script>



<style scoped>
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Add your styles here */
</style>

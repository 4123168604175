<template>
  <div class="container mt-5 mb-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card shadow mb-4">
          <!-- Display session message if available -->
          <div
            v-if="message"
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              class="close"
              @click="message = null"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <b>{{ message }}</b>
          </div>

          <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
          >
            <h6 class="m-0 mt-2  font-weight-bold text-primary">Ubah Password Anda</h6>
          </div>

          <div class="card-body">
            <form @submit.prevent="updatePassword">
              <!-- Current Password Field -->
              <div class="row mb-1">
                <div class="col-md">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="ni ni-lock-circle-open"></i
                        ></span>
                      </div>
                      <input
                        id="current_password"
                        type="password"
                        v-model="oldPassword"
                        class="form-control"
                        :class="{ 'is-invalid': errors.old_password }"
                        placeholder="Password Sekarang"
                        required
                      />
                      <div v-if="errors.old_password" class="invalid-feedback">
                        <strong>{{ errors.old_password }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- New Password Field -->
              <div class="row mb-1">
                <div class="col-md">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="ni ni-lock-circle-open"></i
                        ></span>
                      </div>
                      <input
                        id="new_password"
                        type="password"
                        v-model="newPassword"
                        class="form-control"
                        :class="{ 'is-invalid': errors.new_password }"
                        placeholder="Password Baru"
                        required
                      />
                      <div v-if="errors.new_password" class="invalid-feedback">
                        <strong>{{ errors.new_password }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Confirm Password Field -->
              <div class="row mb-1">
                <div class="col-md">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="ni ni-lock-circle-open"></i
                        ></span>
                      </div>
                      <input
                        id="new_password_confirmation"
                        type="password"
                        v-model="newPasswordConfirmation"
                        class="form-control"
                        placeholder="Konfirmasi Password Baru"
                        required
                      />
                      <div v-if="errors.new_password_confirmation" class="invalid-feedback">
                        <strong>{{ errors.new_password_confirmation }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Submit Button -->
              <div class="row mb-5">
                <div class="col-md-6 offset-md-0">
                  <button type="submit" class="btn btn-primary col-6">
                    SIMPAN
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'UpdatePasswordComponent',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      errors: {},
      message: null,
    };
  },
  methods: {
    async updatePassword() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/setting`, 
        {
          old_password: this.oldPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.newPasswordConfirmation,
        },
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });
        
        // Tampilkan pesan sukses
        this.message = response.data.message;

        // Bersihkan form
        this.clearForm();

        // Redirect ke halaman login setelah beberapa detik
        setTimeout(() => {
          this.$router.push('/login'); // Ubah '/login' sesuai dengan rute login Anda
        }, 2000); // 2 detik delay

      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors;
        }
      }
    },
    clearForm() {
      this.oldPassword = '';
      this.newPassword = '';
      this.newPasswordConfirmation = '';
      this.errors = {};
    },
  },
};
</script>


<style scoped>
.success-message {
  color: green;
}
.error-message {
  color: red;
}
</style>


<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="100vh"
      img-height="100vh"
      style="text-shadow: 1px 1px 2px #333; object-fit: cover"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Dynamic slides with images from API -->
      <b-carousel-slide v-for="(banner, index) in banners" :key="index">
        <template #img>
          <img
            class="d-block img-fluid w-100"
            :src="banner.banner"
            style="object-fit: cover; width: 100vw;"
            :alt="banner.title ? banner.title : 'image slot'"
          />
        </template>
        <div class="carousel-caption">
          <h1 class="text-white">{{ banner.title }}</h1>
          <p style="text-align: justify">
            {{ banner.subtitle }}
          </p>
          <b-button
            :href="'/register'"
            style="background-color: #ea6645; color: white; font-size: 22px"
          >
            <b-icon-arrow-right></b-icon-arrow-right> Lapor Sekarang
          </b-button>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url:`${process.env.VUE_APP_API_BASE_URL}`,
      name: "HeroHome",
      slide: 0,
      sliding: null,
      banners: [], // To store banners fetched from API,
      bannerBaseUrl: process.env.VUE_APP_API_BASE_URL ?? "",
    };
  },
  methods: {
    async fetchBanners() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/banner`);
        const data = await response.json();
        if (data && data.data && data.data.length > 0) {
          this.banners = data.data;
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  mounted() {
    this.fetchBanners(); // Fetch banners when the component is mounted
  },
};
</script>

<style scoped>
/* Make carousel full-screen on mobile */
@media (max-width: 767px) {
  .carousel {
    height: 100vh;
  }
  .carousel-item {
    height: 100vh;
  }
  .carousel-item img {
    object-fit: cover;
    height: 100%;
  }
}

.carousel-caption {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  color: white;
  text-shadow: 1px 1px 2px #000;
}

.carousel-caption h1 {
  font-size: 2.5rem;
  margin-top: -250px;
  margin-bottom: 1rem;
}

.carousel-caption p {
  font-size: 1.25rem;
}
</style>

<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center mt-4">
      <div class="col-lg-10">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col-lg-6 d-none d-lg-block bg-login-image">
                <img :src="require('../../assets/images/login.png')" alt="Login Image" class="img-fluid" />
              </div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div>
                    <h1 class="h4 text-gray-900 mb-4"><b>Masuk Dengan Email</b></h1>
                  </div>
                  <div v-if="successMessage" class="alert alert-success alert-dismissible fade show" role="alert">
                    {{ successMessage }}
                  </div>
                  <div v-if="loginErrorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
                    {{ loginErrorMessage }}
                  </div>
                  <form @submit.prevent="handleSubmit" class="user">
                    <div class="form-group">
                      <label class="text-muted">Email</label>
                      <input
                        type="email"
                        name="email"
                        class="form-control form-control-user"
                        :class="{ 'is-invalid': emailError }"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Masukan Email Anda"
                        v-model="email"
                        required
                        autofocus
                      />
                      <div v-if="emailError" class="invalid-feedback">
                        {{ emailError }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="text-muted">Password</label>
                      <input
                        type="password"
                        name="password"
                        class="form-control form-control-user"
                        :class="{ 'is-invalid': passwordError }"
                        id="password"
                        placeholder="Masukan Password Anda"
                        v-model="password"
                        required
                      />
                      <div v-if="passwordError" class="invalid-feedback">
                        {{ passwordError }}
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-user btn-block">
                      Masuk
                    </button>
                  </form>
                  <hr />
                  <div class="text-center">
                    <p class="small">
                      Belum Punya Akun?
                      <router-link class="text-primary" to="/register"><b>Daftar Sekarang</b></router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LoginComponent",
  data() {
    return {
      email: "",
      password: "",
      emailError: null,
      passwordError: null,
      successMessage: null,
      loginErrorMessage: null,
    };
  },
  methods: {
  async handleSubmit() {
    this.emailError = null;
    this.passwordError = null;
    this.loginErrorMessage = null;

    try {
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/login`, {
        email: this.email,
        password: this.password,
      });

      if (response.data.status && response.data.code === 200) {
        // Store the token in localStorage
        localStorage.setItem('token', response.data.token.Bearer);

        // Set success message
        this.successMessage = 'Login Berhasil';

        // Redirect to the dashboard after a slight delay to show the message
        setTimeout(() => {
          this.$router.push('/dashboard');
        }, 1500);
      } else {
        // If the login is not successful, display an error message
        this.loginErrorMessage = 'Email dan password salah';
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          // Handle unauthorized error
          this.loginErrorMessage = 'Email atau password salah.';
        } else {
          console.error('API Error:', error.response.data);
          const errors = error.response.data.errors;
          if (errors) {
            this.emailError = errors.email ? errors.email[0] : null;
            this.passwordError = errors.password ? errors.password[0] : null;
          } else {
            this.loginErrorMessage = 'Terjadi kesalahan. Silakan coba lagi.';
          }
        }
      } else if (error.request) {
        console.error('No response received:', error.request);
        this.loginErrorMessage = 'Tidak ada respons dari server. Silakan coba lagi nanti.';
      } else {
        console.error('Error:', error.message);
        this.loginErrorMessage = 'Terjadi kesalahan yang tidak terduga.';
      }
    }
  },
},

};
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.card {
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 2rem;
}

.bg-login-image {
  background-color: #fff;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.form-control {
  border-radius: 2rem;
  padding: 1rem;
  font-size: 1rem;
}

.form-control-user {
  border-radius: 2rem;
}

.btn-user {
  border-radius: 2rem;
}

.text-muted {
  color: #6c757d !important;
}

.text-primary {
  color: #5e72e4 !important;
}

.text-gray-900 {
  color: #3a3b45 !important;
}
</style>

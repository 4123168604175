<template>
    <div>
      <!-- Page Heading -->
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
      </div>
      <!-- Content Row -->
      <div class="row">
        <!-- Total Unit Usaha Card -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow h-100 py-2 custom-border-left-primary">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Total Unit Usaha
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{ totalUnitUsaha }} Unit Usaha</div>
                </div>
                <div class="col-auto">
                  <b-icon icon="card-list" class="text-dark" scale="2"></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Status Laporan Disetujui Card -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-success shadow h-100 py-2 custom-border-left-success">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Status Laporan Disetujui
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{ approved }} LKU</div>
                </div>
                <div class="col-auto">
                  <b-icon icon="clipboard-check" class="text-dark" scale="2"></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Status Laporan Revisi Card -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-danger shadow h-100 py-2 custom-border-left-danger">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                    Status Laporan Revisi
                  </div>
                  <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ revisi }} LKU</div>
                </div>
                <div class="col-auto">
                  <b-icon icon="clipboard-x" class="text-dark" scale="2"></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Status Laporan Pending Card -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-warning shadow h-100 py-2 custom-border-left-warning">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    Draf Laporan
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{ pending }} LKU</div>
                </div>
                <div class="col-auto">
                  <b-icon icon="messenger" class="text-dark" scale="2"></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Content Row -->
    </div>
  </template>
  
  <script>
import axios from 'axios';

export default {
  name: 'ReportComponent',
  data() {
    return {
      totalUnitUsaha: 0, // Data from /unit-usaha API
      approved: 0,       // Data from /lku API
      revisi: 0,         // Data from /lku-revisi API
      pending: 0,        // Data from /lku-pending API
      errors: [],        // To store error messages
    };
  },
  mounted() {
    // Call all fetchData methods when the component is mounted
    this.fetchTotalUnitUsaha();
    this.fetchApprovedData();
    this.fetchRevisiData();
    this.fetchPendingData(); // Fetch pending data
  },
  methods: {
    // Fetch data from the unit-usaha API
    async fetchTotalUnitUsaha() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/dashboard/unit-usaha`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.totalUnitUsaha = response.data.total_unit_usaha; // Access total_unit_usaha
      } catch (error) {
        this.handleError(error, 'Failed to fetch total unit usaha data.');
      }
    },

    // Fetch data from the lku API (approved data)
    async fetchApprovedData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/dashboard/lku`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.approved = response.data.total_laporan_lku_disetujui; // Assuming `approved` is in the response
      } catch (error) {
        this.handleError(error, 'Failed to fetch approved data.');
      }
    },

    // Fetch data from the lku-revisi API (revisi data)
    async fetchRevisiData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/dashboard/lku-revisi`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.revisi = response.data.total_laporan_lku_revisi; // Assuming `revisi` is in the response
      } catch (error) {
        this.handleError(error, 'Failed to fetch revisi data.');
      }
    },

    // Fetch data from the lku-pending API (pending data)
    async fetchPendingData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/dashboard/lku-pending`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.pending = response.data.total_laporan_lku_pending; // Assuming `pending` is in the response
      } catch (error) {
        this.handleError(error, 'Failed to fetch pending data.');
      }
    },

    // Handle errors
    handleError(error, message) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        this.errors.push(message + ' Server error.');
      } else if (error.request) {
        console.error('Error request:', error.request);
        this.errors.push(message + ' No response from server.');
      } else {
        console.error('Error message:', error.message);
        this.errors.push(message + ' An unexpected error occurred.');
      }
    },
  },
};
</script>


  
  <style scoped>
  /* Custom border-left colors */
  .card.custom-border-left-primary {
    border-left: 5px solid #007bff !important; /* Primary color */
  }
  
  .card.custom-border-left-success {
    border-left: 5px solid #28a745 !important; /* Success color */
  }
  
  .card.custom-border-left-danger {
    border-left: 5px solid #dc3545 !important; /* Danger color */
  }
  
  .card.custom-border-left-warning {
    border-left: 5px solid #ffc107 !important; /* Warning color */
  }
  </style>
  
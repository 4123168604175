<template>
<div class="container-fluid text-white footer mt-5 pt-5" style="background: rgb(251,216,107);
background: linear-gradient(321deg, rgba(251,216,107,1) 0%, rgba(74,101,175,1) 50%, rgba(0,0,0,1) 100%);">
<div class="container mt-2">
   <!-- About Start -->
   
            <div class="container px-lg-5">
                <div class="row g-5 align-items-center">
                  
                  <div class="col-lg-7 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="section-title position-relative mb-0 pb-4">
                            <h5 class="mb-0 text-white"><strong>Video Tutorial </strong>
                            </h5>
                        </div>
                        <h1 class="mb-2 text-white">
                         <strong> Cara Menambahkan Data Unit Usaha</strong></h1>
                          <p>Laporan Kegiatan Usaha Dinas Pariwisata dan Ekonomi Kreatif Jakarta</p>
                        
                    </div>
                   
                    <div class="col-lg-5 mb-5">
                      <iframe width="100%" height="300" src="https://www.youtube.com/embed/RoXrygB9dNI?si=jZD6QSNWIIyj37Fi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> </div>
                   
                </div>
            </div>
        
        <!-- About End -->
 
</div>  
</div>



    
  </template>
  <script>
  
  export default {
    name: "UsahaComponent"
   
  }
  </script>
  
  <style>
    
  </style>
  
<template>
  <div class="row">
    <!-- Area Chart for Semester 1 -->
    <div class="col-xl-6 col-lg-6">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 text-primary">Total Pengunjung Semester 1</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-area">
            <canvas id="myChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- Area Chart for Semester 2 -->
    <div class="col-xl-6 col-lg-6">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 text-primary">Total Pengunjung Semester 2</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <div class="chart-area">
            <canvas id="myChartSemester"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Chart from 'chart.js/auto';

export default {
  name: 'ChartComponent',
  data() {
    return {
      pengunjungSemester1: [],
      pengunjungSemester2: [],
      isLoading: false,
      errorMessage: ''
    };
  },
  methods: {
    async fetchPengunjungData() {
      this.isLoading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/dashboard/pengunjung?tahun=2024&semester=1`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const pengunjungData = response.data.total_pengunjung;

        // Extract data for semester 1 and semester 2
        const semester1 = pengunjungData.semester_1;
        const semester2 = pengunjungData.semester_2;

        // Update data for the charts
        this.pengunjungSemester1 = [
          semester1.januari,
          semester1.februari,
          semester1.maret,
          semester1.april,
          semester1.mei,
          semester1.juni
        ];
        this.pengunjungSemester2 = [
          semester2.juli,
          semester2.agustus,
          semester2.september,
          semester2.oktober,
          semester2.november,
          semester2.desember
        ];

        // Initialize the charts after data is fetched
        this.initCharts();
      } catch (error) {
        this.errorMessage = 'Failed to fetch pengunjung data.';
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    initCharts() {
      const ctx1 = document.getElementById('myChart').getContext('2d');
      const ctx2 = document.getElementById('myChartSemester').getContext('2d');

      // Chart for Semester 1
      new Chart(ctx1, {
        type: 'line',
        data: {
          labels: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni'],
          datasets: [{
            label: 'Pengunjung',
            data: this.pengunjungSemester1,
            backgroundColor: ['#007bff'],
            borderColor: ['#007bff'],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: { beginAtZero: true }
          },
          maintainAspectRatio: false
        }
      });

      // Chart for Semester 2
      new Chart(ctx2, {
        type: 'line',
        data: {
          labels: ['Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
          datasets: [{
            label: 'Pengunjung',
            data: this.pengunjungSemester2,
            backgroundColor: ['#007bff'],
            borderColor: ['#007bff'],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: { beginAtZero: true }
          },
          maintainAspectRatio: false
        }
      });
    }
  },
  mounted() {
    // Fetch data when the component is mounted
    this.fetchPengunjungData();
  }
};
</script>

<style>
.chart-area {
  position: relative;
  height: 300px; /* Adjust the height as needed */
}

canvas {
  max-width: 100%;
  height: auto !important;
}
</style>

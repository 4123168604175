import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import verificationView from '../views/verificationView.vue';
import RegisterView from '../views/RegisterView.vue';
import DashboardView from '../views/DashboardView.vue';
import PerusahaanView from '../views/PerusahaanView.vue';
import UnitUsahaView from '../views/UnitUsahaView.vue';
import CreateUnitUsahaView from '../views/CreateUnitUsahaView.vue';
import EditUnitUsahaView from '../views/EditUnitUsahaView.vue';
import ViewUnitUsahaView from '../views/ViewUnitUsahaView.vue';
import LkuView from '../views/LkuView.vue';
import EditLkuView from '../views/EditLkuView.vue';
import ViewLkuView from '../views/ViewLkuView.vue';
import CreateLkuView from '../views/CreateLkuView.vue';
import UpdatePasswordView from '../views/UpdatePasswordView.vue';
// Use VueRouter
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/verification',
    name: 'verification',
    component: verificationView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/perusahaan',
    name: 'perusahaan',
    component: PerusahaanView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha',
    name: 'unit-usaha',
    component: UnitUsahaView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha/create',
    name: 'create-unit-usaha',
    component: CreateUnitUsahaView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha/edit',
    name: 'edit-unit-usaha',
    component: EditUnitUsahaView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha/view',
    name: 'view-unit-usaha',
    component: ViewUnitUsahaView,
    meta: { requiresAuth: true }
  },

  {
    path: '/dashboard/setting',
    name: 'Setting',
    component: UpdatePasswordView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha/lku',
    name: 'index-lku',
    component: LkuView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha/lku/create',
    name: 'index-lku-create',
    component: CreateLkuView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha/lku/view',
    name: 'index-lku-view',
    component: ViewLkuView,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/unit-usaha/lku/edit',
    name: 'edit-lku-view',
    component: EditLkuView,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    console.log('Token:', token); // Debug log
    if (token) {
      next(); // Allow access if token exists
    } else {
      console.log('No token found. Redirecting to login.'); // Debug log
      next('/login'); // Redirect to login if no token
    }
  } else {
    next(); // Allow access to routes that don't require auth
  }
});

export default router;

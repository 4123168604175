<template>
  <div class="login">
    <Navbar />
   <RegisterComponent />
    <FooterComponent />

  </div>
</template>

<script>

// @ is an alias to /src
import Navbar from '@/components/home/Navbar.vue'
import FooterComponent from '@/components/home/FooterComponent.vue'
import RegisterComponent from '@/components/register/RegisterComponent.vue'

export default {
  name: 'RegisterView',
  components: {
    Navbar,
    RegisterComponent,
    FooterComponent
    
  }
}
</script>
